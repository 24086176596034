/* eslint-disable prefer-template */
import { useRef, useState, useEffect } from 'react';
import { Rate } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y, Autoplay } from 'swiper';
import { uniqueId } from 'lodash';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

const BuyerTestimonial = ({ data }) => {
  const navigationPreviousRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const shortenNames = (names) => {
    const splitName = names.split(' ');
    let shortName = splitName[0];
    if (splitName.length > 1) {
      shortName += ' ' + splitName[1][0].toUpperCase();
      if (splitName.length > 2) {
        shortName += ' ' + splitName[2][0].toUpperCase();
      }
    }
    return shortName;
  };

  // Once swiperInstance is set and refs are available, reinitialize navigation
  useEffect(() => {
    if (swiperInstance && swiperInstance.navigation) {
      // Update navigation elements with the ref values
      swiperInstance.params.navigation.prevEl = navigationPreviousRef.current;
      swiperInstance.params.navigation.nextEl = navigationNextRef.current;
      swiperInstance.navigation.destroy();
      swiperInstance.navigation.init();
      swiperInstance.navigation.update();
    }
  }, [swiperInstance]);

  return (
    <div className="bg-theme-primary-main/10 pt-5 pb-[30px] rounded-[15px]">
      <h2 className="text-[18px] md:text-xl font-medium mb-5 pl-[36px]">Ulasan Pembeli</h2>
      <div className="px-[36px] relative">
        <Swiper
          modules={[Navigation, A11y, Autoplay]}
          slidesPerView="1"
          spaceBetween={36}
          breakpoints={{
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1536: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          className="relative"
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          navigation={{
            prevEl: navigationPreviousRef.current,
            nextEl: navigationNextRef.current,
          }}
          onSwiper={(swiper) => {
            setSwiperInstance(swiper);
          }}
          onSlideChange={(swiper) => {
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
          }}
        >
          {data?.map((testimony) => (
            <SwiperSlide key={uniqueId()}>
              <div className="p-2 min-[281px]:p-[15px] min-[348px]:p-[20px] sm:p-[30px] relative bg-white h-[191px] flex flex-col justify-between rounded-[20px]">
                <p className="text-[12px] sm:text-[13px] font-medium leading-[19.5px] tracking-[0.2px]">
                  {`"${testimony?.review}"`}
                </p>
                <div>
                  <p className="text-[11px] font-medium leading-[16.5px] tracking-[0.2px]">
                    {shortenNames(testimony?.name)}
                  </p>
                  <p className="text-[9px] leading-[13.5px] tracking-[0.2px] text-[#666666]">
                    {testimony?.occupation}
                  </p>
                  <Rate disabled allowHalf defaultValue={testimony?.rate} className="text-[15px]" />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          ref={navigationPreviousRef}
          className="absolute top-[50%] transform translate-y-[-50%] z-10 left-0"
        >
          <ChevronLeftIcon
            className={`h-[30px] sm:h-[40px] w-[30px] sm:w-[40px] text-theme-primary-main transition-all duration-300 ${
              isBeginning ? 'cursor-default opacity-20' : 'cursor-pointer'
            }`}
          />
        </div>
        <div
          ref={navigationNextRef}
          className="absolute top-[50%] transform translate-y-[-50%] z-10 right-0"
        >
          <ChevronRightIcon
            className={`h-[30px] sm:h-[40px] w-[30px] sm:w-[40px] text-theme-primary-main transition-all duration-300 ${
              isEnd ? 'cursor-default opacity-20' : 'cursor-pointer'
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default BuyerTestimonial;
